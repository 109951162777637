<template>
  <svg
    width="100%"
    viewBox="0 0 116 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2059_11852)">
      <path
        d="M32.2078 18.0923V8.21286C32.2078 7.19805 33.0306 6.37543 34.0454 6.37543H34.1801V18.0923H32.2078Z"
        fill="currentColor"
      />
      <path
        d="M37.0391 18.0923V6.37543H39.1142L43.8649 14.8786V6.37543H43.9138C44.9286 6.37543 45.7514 7.19805 45.7514 8.21286V18.0923H43.6761L38.9256 9.5891V18.0923H37.0391Z"
        fill="currentColor"
      />
      <path
        d="M51.9669 18.3769C50.7435 18.3769 49.7888 18.0031 49.1027 17.2555C48.4167 16.5077 48.0737 15.5146 48.0737 14.2759H50.046C50.046 15.0013 50.2062 15.5816 50.5262 16.0168C50.8579 16.4407 51.3381 16.6528 51.9669 16.6528C52.5958 16.6528 53.0759 16.4407 53.4075 16.0168C53.7506 15.5816 53.922 15.0068 53.922 14.2927V8.21286C53.922 7.19805 54.7448 6.37543 55.7596 6.37543H55.8943V14.2927C55.8943 15.5312 55.5456 16.5246 54.8482 17.2721C54.1622 18.0086 53.2017 18.3769 51.9669 18.3769Z"
        fill="currentColor"
      />
      <path
        d="M58.573 6.37543H65.5018C65.5018 7.32755 64.7299 8.09949 63.7778 8.09949H60.5453V11.4136H65.5018V13.1377H60.5453V16.3682H65.5018V18.0923H58.573V11.4136V8.09949V6.37543Z"
        fill="currentColor"
      />
      <path
        d="M108.635 6.37543H115.564C115.564 7.32755 114.792 8.09949 113.84 8.09949H110.608V11.4136H115.564V13.1377H110.608V16.3682H115.564V18.0923H108.635V11.4136V8.09949V6.37543Z"
        fill="currentColor"
      />
      <path
        d="M73.5654 18.3765C71.8544 18.3765 70.5 17.8075 69.5018 16.6691C68.5039 15.5309 68.0049 14.0524 68.0049 12.2334C68.0049 10.4146 68.5039 8.93607 69.5018 7.79768C70.5 6.65947 71.8544 6.09045 73.5654 6.09045C75.0012 6.09045 76.1826 6.49218 77.1095 7.29565C78.0362 8.08783 78.581 9.12018 78.744 10.3922H78.303C77.4372 10.3922 76.7468 9.7381 76.2335 9.04102C76.0855 8.84007 75.9141 8.65989 75.7193 8.50084C75.1694 8.04323 74.4564 7.81451 73.5806 7.81451C72.4094 7.81451 71.4929 8.21624 70.831 9.01971C70.1792 9.81189 69.8532 10.8833 69.8532 12.2334C69.8532 13.5836 70.1792 14.6606 70.831 15.4641C71.4929 16.2562 72.4094 16.6524 73.5806 16.6524C74.4564 16.6524 75.1694 16.4237 75.7193 15.9661C75.9141 15.807 76.0855 15.6269 76.2335 15.4259C76.7468 14.7288 77.4372 14.0748 78.303 14.0748H78.744C78.581 15.3468 78.0362 16.3847 77.1095 17.1881C76.1826 17.9803 75.0012 18.3765 73.5654 18.3765Z"
        fill="currentColor"
      />
      <path
        d="M83.8364 18.0923V8.18313H80.355C80.355 7.1848 81.1644 6.37543 82.1627 6.37543H89.3075V8.18313H85.8087V18.0923H83.8364Z"
        fill="currentColor"
      />
      <path
        d="M91.8105 18.0923V8.21286C91.8105 7.19805 92.6332 6.37543 93.6482 6.37543H93.7828V18.0923H91.8105Z"
        fill="currentColor"
      />
      <path
        d="M100.264 18.0923L96.2852 6.37543H97.0874C97.8865 6.37543 98.5942 6.89197 98.8376 7.65316L101.207 15.0627L103.577 7.65316C103.82 6.89197 104.528 6.37543 105.327 6.37543H106.129L102.151 18.0923H100.264Z"
        fill="currentColor"
      />
      <path
        d="M2.9056 4.20823C3.05428 4.02125 3.21184 3.84143 3.36941 3.66161C3.37649 3.65265 3.39246 3.65086 3.39955 3.6419C3.4137 3.62417 3.43677 3.61343 3.45094 3.5957L3.46509 3.57779C3.57493 3.47623 3.69183 3.36573 3.82658 3.26955C4.30335 2.90758 4.79816 2.63265 5.31991 2.45158C6.99336 1.86411 8.85624 2.22626 10.317 3.60537C12.3568 5.51731 12.1742 8.59737 10.5459 10.6445C8.48818 13.6965 4.95228 17.9545 9.84812 21.7687C10.7284 22.4545 11.3814 23.0199 14.1543 23.8212C12.3407 24.1555 10.6593 24.0514 8.78764 23.5734C7.4637 22.8261 5.38224 21.2262 4.67408 19.0651C3.60375 15.7881 6.55842 10.8892 7.98633 9.00232C9.94699 6.39116 6.7746 3.56435 4.43909 6.72017C3.21825 8.36489 1.08246 13.0187 1.82467 16.4708C2.25864 18.4284 2.83709 19.8553 5.13062 21.8155C4.7053 21.5645 4.2923 21.2798 3.89164 20.9613C-1.4372 15.9976 -0.818947 8.32244 2.9056 4.20823Z"
        fill="url(#paint0_linear_2059_11852)"
      />
      <path
        d="M21.0457 19.8772C20.8971 20.0642 20.7394 20.244 20.5818 20.4238C20.5748 20.4328 20.5587 20.4346 20.5517 20.4434C20.5376 20.4613 20.5145 20.472 20.5003 20.4897L20.4862 20.5077C20.3764 20.6092 20.2594 20.7195 20.1248 20.8159C19.6478 21.1779 19.1531 21.4528 18.6314 21.6337C16.9578 22.2213 15.0951 21.8592 13.6342 20.4801C11.5945 18.5681 11.7772 15.4881 13.4055 13.4407C15.463 10.389 18.9989 6.13094 14.1031 2.31673C13.2228 1.63094 12.5698 1.06533 9.79688 0.264105C11.6105 -0.069997 13.2919 0.0339187 15.1636 0.511966C16.4875 1.2593 18.5691 2.85924 19.2772 5.02031C20.3476 8.29739 17.3929 13.1963 15.9649 15.0829C14.0044 17.6943 17.1767 20.5209 19.5122 17.3653C20.733 15.7206 22.8688 11.0667 22.1266 7.61464C21.6926 5.65703 21.1141 4.2301 18.8207 2.26999C19.2459 2.52091 19.6589 2.80551 20.0596 3.12413C25.3885 8.08784 24.7702 15.763 21.0457 19.8772Z"
        fill="url(#paint1_linear_2059_11852)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2059_11852"
        x1="0.0021928"
        y1="12.0426"
        x2="23.949"
        y2="12.0426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FA" />
        <stop offset="1" stop-color="#00F2FE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2059_11852"
        x1="0.00217078"
        y1="12.0426"
        x2="23.949"
        y2="12.0426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FA" />
        <stop offset="1" stop-color="#00F2FE" />
      </linearGradient>
      <clipPath id="clip0_2059_11852">
        <rect width="115.522" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
